import React, { useEffect, useRef, useState } from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import PhoneInput from 'react-phone-input-2';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import {
  Form,
  FieldTextInput,
  Button,
  H5,
  NamedLink,
  H3,
  Modal,
  FieldSelect,
  FieldLocationAutocompleteInput,
  H4,
  FieldRadioButton,
  IconSpinner,
  FieldMultiSelect,
} from '../../../components';
import { miles, companySize } from '../../../config/configListing';
import { availabilityVendor, countryNames, vendorServices } from '../../../config/configData';
import coverImage from '../../../assets/image/signupimage.jpg';
import css from './SignupForm.module.css';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
  required,
} from '../../../util/validators';
import IconCurrentLocation from '../../../components/LocationAutocompleteInput/IconCurrentLocation';

const identity = v => v;

const SignupFormComponent = props => {
  if (typeof window === 'undefined') return null;
  require('react-phone-input-2/lib/style.css');
  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          invalid,
          intl,
          termsAndConditions,
          onManageDisableScrolling,
          autoFocus,
          values,
          form,
          openModal,
          modalArray,
          setOpenModal,
          signupInProgress,
          signupSuccess,
          isSignUpSuccessfull,
          setIsSignUpSuccessfull,
          loginOrSignupError,
          fetchUser,
          currentUser,
          getOtpSignup,
          onOtpSignUpCheck,
          otpInProgress,
          otpCheckData,
          onCheckPhoneNumber,
          doesPhoneExist,
          otpCheckInProgress,
          otpCheckError,
          onUpdateProfile,
          isAuthenticated,
          fetchUserInProgress,
          loginInProgress,
        } = fieldRenderProps;

        const verifyOtp = otpCheckData?.status == 'approved';
        const verifyOtpError = otpCheckData?.valid == false;
        const vendorPhoneNumber = currentUser?.attributes?.profile?.publicData?.phoneNumber;
        const country = currentUser?.attributes?.profile?.publicData?.country;
        const checkPhoneNumber = doesPhoneExist?.status == 'user Exist';

        const customerListings = fetchUser?.data?.data;

        const matchedResponse = customerListings?.filter(item => {
          const userCountry = item?.attributes?.publicData?.location?.search;

          const parts = location?.search?.split(' ') ?? '';
          const countryfromLoc = parts[parts?.length - 1] ?? '';
          return country
            ? userCountry?.toLowerCase()?.includes(country?.toLowerCase())
            : userCountry.toLowerCase()?.includes(countryfromLoc?.toLowerCase());
        });

        const filteredUsers = matchedResponse && matchedResponse.length;
        // email
        const emailRequired = validators.required(
          intl.formatMessage({
            id: 'SignupForm.emailRequired',
          })
        );
        const emailValid = validators.emailFormatValid(
          intl.formatMessage({
            id: 'SignupForm.emailInvalid',
          })
        );

        const addressRequiredMessage = intl.formatMessage({
          id: 'EditListingLocationForm.addressRequired',
        });
        const addressNotRecognizedMessage = intl.formatMessage({
          id: 'EditListingLocationForm.addressNotRecognized',
        });

        // country
        const countryLabel = intl.formatMessage({
          id: 'EditListingDescriptionForm.countryLabel',
        });
        const countryPlaceholder = intl.formatMessage({
          id: 'EditListingDescriptionForm.countryPlaceholder',
        });
        const countryRequired = validators.required(
          intl.formatMessage({
            id: 'EditListingDescriptionForm.countryRequired',
          })
        );

        // password
        const passwordRequiredMessage = intl.formatMessage({
          id: 'SignupForm.passwordRequired',
        });
        const passwordMinLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooShort',
          },
          {
            minLength: validators.PASSWORD_MIN_LENGTH,
          }
        );
        const passwordMaxLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooLong',
          },
          {
            maxLength: validators.PASSWORD_MAX_LENGTH,
          }
        );
        const passwordMinLength = validators.minLength(
          passwordMinLengthMessage,
          validators.PASSWORD_MIN_LENGTH
        );
        const passwordMaxLength = validators.maxLength(
          passwordMaxLengthMessage,
          validators.PASSWORD_MAX_LENGTH
        );
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
        const passwordValidators = validators.composeValidators(
          passwordRequired,
          passwordMinLength,
          passwordMaxLength
        );

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = !(
          values?.fullName &&
          values?.phoneNumber?.length > 10 &&
          values?.password &&
          values?.email &&
          values?.companyName &&
          values?.terms?.length &&
          !checkPhoneNumber
        );
        const disableAvailabilityVendor =
          (values?.availabilityVendor &&
            (values?.availabilityVendor == 'I serve customers nationwide' && values?.country)) ||
          (values?.availabilityVendor == 'I serve customers within' &&
            values?.distance &&
            values?.location?.selectedPlace);

        const [phoneNumber, setPhoneNumber] = useState({
          value: '',
          countryCode: '',
          country: '',
          number: '',
        });

        const handleOnChange = (value, country) => {
          const { name = '', dialCode = '', countryCode = '', format } = country;
          form.change('phoneNumber', '+' + value);
          form.change('dialCode', dialCode);
          setPhoneNumber({
            value: value,
            countryCode: countryCode,
            countryName: name,
            dialCode: dialCode,
          });

          let filterLength = 0;
          for (let i = 0; i < format.length; i++) {
            if (format[i] == '.') {
              filterLength += 1;
            }
          }

          if (value && filterLength && filterLength == value.length) {
            onCheckPhoneNumber('+' + value);
          }
        };

        const [otp, setOTP] = useState(['', '', '', '', '', '']);
        const otpFields = useRef([]);
        const [otpTimer, setOtpTimer] = useState(59);
        const intervalRef = useRef(null);

        // useEffect(() => {
        //   sendOTPAfterTimer();
        //   return () => clearInterval(intervalRef.current);
        // }, []);

        const handleChange = (index, value) => {
          const updatedOTP = [...otp];
          updatedOTP[index] = value;
          setOTP(updatedOTP);
          if (value !== '' && index < 5) {
            otpFields.current[index + 1].focus();
          }
        };

        const handleKeyPress = (index, e) => {
          console.log(e.key);
          if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
            e.preventDefault();
            setTimeout(() => otpFields.current[index - 1].focus(), 0);
          }
        };

        const sendOTPAfterTimer = () => {
          intervalRef.current = setInterval(() => {
            setOtpTimer(prevValue => {
              if (prevValue === 0) {
                clearInterval(intervalRef.current);
                return 0;
              } else {
                return prevValue - 1;
              }
            });
          }, 1000);
        };

        const handleSignup = async () => {
          try {
            if (!isAuthenticated) {
              props.onSubmitSignupForm(values).then(async signupResponse => {
                if (signupResponse == true) {
                  getOtpSignup({ phoneNumber: values?.phoneNumber }).then(async response => {
                    if (response?.valid === false) {
                      setOpenModal('three');
                      sendOTPAfterTimer();
                    }
                  });
                }
              });
            } else {
              getOtpSignup({ phoneNumber: values?.phoneNumber }).then(async response => {
                if (response?.valid === false) {
                  setOpenModal('three');
                  sendOTPAfterTimer();
                }
                const profile = {
                  publicData: {
                    phoneNumber: values?.phoneNumber,
                  },
                };
                onUpdateProfile(profile);
              });
            }
          } catch (error) {
            console.error('Please try after some time', error);
          }
        };
      
        return (
          <Form className={classes}>
            <div className={css.signupWrapper}>
              <div className={css.leftTextSection}>
                <div className={css.signupText}>
                  <span>
                    <FormattedMessage id="SignupForm.heading" />
                  </span>
                  <p>
                  <FormattedMessage id="SignupForm.Subheading" />
                  </p>
                </div>
                <div className={css.searchSection}>
                  <div>
                    <FieldMultiSelect
                      isMulti={true}
                      isRequired={true}
                      className={css.inputBox}
                      id={'category'}
                      name={'category'}
                      fontClass={css.fontClass}
                      options={vendorServices}
                      autoComplete={'languagesSpoken'}
                      placeholder="Select one or more services."
                      validate={required}
                    />
                  </div>
                  <Button
                    type="button"
                    disabled={!values?.category?.length > 0}
                    onClick={() => setOpenModal('one')}
                    className={css.getStartedBtn}
                  >
                    Get Started
                  </Button>
                  <Modal
                    id="VerifyDeletingPaymentMethod"
                    isOpen={openModal && modalArray.includes(openModal)}
                    onClose={() => {
                      setOpenModal(false);
                    }}
                    onManageDisableScrolling={onManageDisableScrolling}
                    className={classNames(
                      css.stepsModal,
                      openModal == 'three' && css.modalCloseBtnHide
                    )}
                  >
                    <div>
                      {openModal == 'one' ? (
                        <div>
                          <h4 className={css.modalTitle}>
                            <FormattedMessage id="SignupForm.SeeLeads" />
                          </h4>
                          <p className={css.subtitle}>
                            <FormattedMessage id="SignupForm.Leadslocation" />
                          </p>
                          <div>
                            {availabilityVendor &&
                              availabilityVendor?.map((st, i) => (
                                <div className={css.colorBox} key={st.label + '_' + i}>
                                  <FieldRadioButton
                                    id={st.option}
                                    key={st.option}
                                    name={'availabilityVendor'}
                                    value={st.option}
                                    label={st.label}
                                    onChange={e => {
                                      console.log(e.target.value)
                                      form.change('availabilityVendor', e.target.value)
                                      
                                      if(e.target.value === 'I serve customers nationwide'){
                                        form.change('country', 'Canada')
                                      }
                                      else{
                                        form.change('country', '')
                                      }
                                    }}
                                  />
                                </div>
                              ))}
                          </div>
                          {values?.availabilityVendor == 'I serve customers nationwide' ? (
                            <div>
                              <FieldSelect
                                id={'country'}
                                name="country"
                                className={css.field}
                                label={countryLabel}
                                disabled={true}
                              >
                                <option disabled value="">
                                  {countryPlaceholder}
                                </option>
                                {countryNames &&
                                  countryNames.map(country => {
                                    return (
                                      <option key={country.option} value={country.label}>
                                        {country.label}
                                      </option>
                                    );
                                  })}
                              </FieldSelect>
                            </div>
                          ) : null}
                          {values?.availabilityVendor == 'I serve customers within' ? (
                            <div className={css.locationInputWrapper}>
                              <div>
                                <div>
                                  <FieldSelect
                                    id={`${formId}.distance`}
                                    className={css.quantityField}
                                    name="distance"
                                    disabled={
                                      !values?.availabilityVendor == 'I serve customers within'
                                    }
                                  >
                                    <option disabled value="">
                                      Select any one
                                    </option>
                                    {miles &&
                                      miles.length &&
                                      miles.map(item => (
                                        <option key={item.option} value={item.label}>
                                          {item.label}
                                        </option>
                                      ))}
                                  </FieldSelect>
                                </div>
                              </div>
                              <p>from</p>
                              <div>
                                <div>
                                  <FieldLocationAutocompleteInput
                                    rootClassName={css.locationAddressForm}
                                    inputClassName={css.locationAutocompleteInput}
                                    iconClassName={css.locationAutocompleteInputIcon}
                                    predictionsClassName={css.predictionsRoot}
                                    validClassName={css.validLocation}
                                    autoFocus={autoFocus}
                                    name="location"
                                    placeholder={intl.formatMessage({
                                      id: 'EditListingLocationForm.addressPlaceholder',
                                    })}
                                    useDefaultPredictions={false}
                                    format={identity}
                                    valueFromForm={values.location}
                                    validate={composeValidators(
                                      autocompleteSearchRequired(addressRequiredMessage),
                                      autocompletePlaceSelected(addressNotRecognizedMessage)
                                    )}
                                    disabled={
                                      !values?.availabilityVendor == 'I serve customers within'
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className={css.buttonGroup}>
                            <Button
                              type="button"
                              disabled={!disableAvailabilityVendor}
                              onClick={() => setOpenModal('two')}
                            >
                              Next
                            </Button>
                          </div>
                        </div>
                      ) : openModal == 'two' ? (
                        <div>
                          <h4 className={css.modalTitle}>Some details about you</h4>
                          <p className={css.subtitle}>
                            You’re just a few steps away from viewing our services
                          </p>
                          <div>
                            <FieldTextInput
                              className={css.password}
                              type="text"
                              id={formId ? `${formId}.fullName` : 'fullName'}
                              name="fullName"
                              autoComplete="given-name"
                              label={intl.formatMessage({
                                id: 'SignupForm.fullNameLabel',
                              })}
                              placeholder={intl.formatMessage({
                                id: 'SignupForm.fullNamePlaceholder',
                              })}
                              validate={validators.required(
                                intl.formatMessage({
                                  id: 'SignupForm.fullNameRequired',
                                })
                              )}
                            />
                            <FieldTextInput
                              className={css.password}
                              type="text"
                              id={formId ? `${formId}.companyName` : 'companyName'}
                              name="companyName"
                              autoComplete="given-name"
                              label={intl.formatMessage({
                                id: 'SignupForm.companyNameLabel',
                              })}
                              placeholder={intl.formatMessage({
                                id: 'SignupForm.companyNamePlaceholder',
                              })}
                              validate={validators.required(
                                intl.formatMessage({
                                  id: 'SignupForm.companyNameRequired',
                                })
                              )}
                            />
                          </div>
                          <div className={css.colorRadio}>
                            <FieldTextInput
                              className={css.password}
                              type="email"
                              id={formId ? `${formId}.email` : 'email'}
                              name="email"
                              autoComplete="email"
                              label={intl.formatMessage({
                                id: 'SignupForm.emailLabel',
                              })}
                              placeholder={intl.formatMessage({
                                id: 'SignupForm.emailPlaceholder',
                              })}
                              validate={validators.composeValidators(emailRequired, emailValid)}
                            />
                            <FieldTextInput
                              className={css.password}
                              type="password"
                              id={formId ? `${formId}.password` : 'password'}
                              name="password"
                              autoComplete="new-password"
                              label={intl.formatMessage({
                                id: 'SignupForm.passwordLabel',
                              })}
                              placeholder={intl.formatMessage({
                                id: 'SignupForm.passwordPlaceholder',
                              })}
                              validate={passwordValidators}
                            />
                            <div className={css.phoneWrapper}>
                              <div className={css.phoneLabel}>Phone number</div>
                              <PhoneInput
                                className={css.phoneBox}
                                name="phoneNumber"
                                id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                                country={'ca'}
                                value={phoneNumber.value}
                                onChange={handleOnChange}
                              />
                            </div>
                            <div className={css.validateText}>
                              {checkPhoneNumber
                                ? 'The phone number is already in use. Please try a different phone number.'
                                : null}
                            </div>
                            <FieldTextInput
                              className={css.password}
                              type="text"
                              id={formId ? `${formId}.website` : 'website'}
                              name="website"
                              autoComplete="website"
                              label={intl.formatMessage({
                                id: 'SignupForm.WebsiteLabel',
                              })}
                              placeholder={intl.formatMessage({
                                id: 'SignupForm.WebsitePlaceholder',
                              })}
                            />
                          </div>
                          <div className={css.venderError}>{loginOrSignupError}</div>
                          <div className={css.termsAndConditions}>{termsAndConditions}</div>
                          <div className={css.actionButtons}>
                            <Button type="button" onClick={() => setOpenModal('one')}>
                              Back
                            </Button>

                            <Button type="button" onClick={handleSignup} disabled={submitDisabled}>
                              {(signupInProgress && !signupSuccess) ||
                              fetchUserInProgress ||
                              otpInProgress ||
                              loginInProgress ? (
                                <IconSpinner />
                              ) : (
                                'Next'
                              )}
                            </Button>
                          </div>
                        </div>
                      ) : openModal == 'three' ? (
                        <div>
                          <div className={css.bodyContent}>
                            <div className={css.otpVerification}>
                              <FormattedMessage id="AuthenticationPage.otpVerification" />
                            </div>
                            <label className={css.labelOtp}>
                              <FormattedMessage id="AuthenticationPage.pleaseEnterDigitOtp" />
                            </label>
                            <div className={css.optInputWrapper}>
                              {otp.map((value, index) => (
                                <input
                                  key={index}
                                  type="text"
                                  maxLength="1"
                                  value={value}
                                  onChange={e => handleChange(index, e.target.value)}
                                  ref={input => (otpFields.current[index] = input)}
                                  onKeyDown={e => handleKeyPress(index, e)}
                                />
                              ))}
                            </div>
                          </div>
                          <div className={css.optTimer}>
                            <span className={css.secondRemain}>
                              {otpTimer > 0 ? otpTimer + ' ' + 'seconds remaining' : null}{' '}
                            </span>
                            {otpTimer === 0 && (
                              <div
                                type="button"
                                onClick={e => {
                                  getOtpSignup({ phoneNumber: vendorPhoneNumber });
                                }}
                              >
                                Didn't receive OTP ? Resend OTP
                              </div>
                            )}
                          </div>

                          <Button
                            className={css.submitOtp}
                            type="button"
                            onClick={e => {
                              e.preventDefault();
                              const enteredOTP = otp.join('');
                              onOtpSignUpCheck({
                                phoneNumber: values?.phoneNumber,
                                otp: enteredOTP,
                                userId: currentUser?.id?.uuid,
                              });
                            }}
                            disabled={verifyOtp}
                          >
                            {otpCheckInProgress ? <IconSpinner /> : 'Submit OTP'}
                          </Button>
                          <div className={css.otpText}>
                            {verifyOtp ? (
                              <div className={css.otpVerified}>OTP verified Successfully</div>
                            ) : null}
                            {verifyOtpError ? (
                              <div className={css.invalidOtp}>Invalid OTP Please try again</div>
                            ) : null}
                          </div>
                          <div className={css.actionButtons}>
                            <Button
                              type="button"
                              onClick={() => {
                                setOpenModal('two');
                              }}
                            >
                              Back
                            </Button>

                            <Button
                              type="button"
                              disabled={!verifyOtp}
                              onClick={e => {
                                setOpenModal(false), setIsSignUpSuccessfull(true);
                              }}
                            >
                              Next
                            </Button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Modal>
                </div>
                <Modal
                  id="VerifyDeletingPaymentMethod"
                  isOpen={isSignUpSuccessfull}
                  onClose={() => {
                    setIsSignUpSuccessfull(false);
                  }}
                  onManageDisableScrolling={onManageDisableScrolling}
                  className={classNames(css.stepsModal && css.modalCloseBtnHide)}
                >
                  <div>
                    <h4 className={css.modalTextCenter}>Maximise your leads</h4>
                    <div className={css.signUpLeads}>
                      <div>
                        <h4>{filteredUsers}</h4>
                        <p> current leads available</p>
                      </div>
                      <NamedLink name="ProfileSettingsPage">
                        <Button>View leads</Button>
                      </NamedLink>
                    </div>
                    <div>
                      To get more leads, add more services or expand the service area in the &nbsp;
                      <NamedLink name="SearchPage">lead settings</NamedLink>
                    </div>
                  </div>
                </Modal>
              </div>

              <div className={css.rightImageSection}>
                <img src={coverImage} alt="" />
              </div>
            </div>
            <div className={css.qualitySection}>
              <div className={css.qualitySectionContent}>
                <div>
                  <H5>Streamline Lead Acquisition</H5>
                  <ul>
                    <li>
                      Access a Wide Range of Leads: Choose from local or nationwide leads tailored
                      to your business needs.
                    </li>
                    <li>
                      Receive Instant Lead Notifications: Get immediate updates on new
                      opportunities.
                    </li>
                    <li>
                      Customized Lead Options: Select leads that best fit your specific
                      requirements.
                    </li>
                  </ul>

                  <a href="/p/how-it-works">
                    <FormattedMessage id="SignupForm.howItsWorks" />
                  </a>
                </div>
                <div>
                  <H5>Convert Leads to Clients</H5>
                  <ul>
                    <li>Contact Leads Quickly: Access verified contact details instantly.</li>
                    <li>
                      Follow Up Effectively: Reach out via phone or email to secure the contract.
                    </li>
                  </ul>
                  <a href="/p/listing_page_example">
                    <FormattedMessage id="SignupForm.seeExample" />
                  </a>
                </div>
                <div>
                  <H5>Boost Your Business Growth</H5>
                  <ul>
                    <li>Retain 100% of Your Earnings: Enjoy a FEE-FREE experience.</li>
                    <li>Boost Your Income: Maximize your earnings potential.</li>
                  </ul>
                  <a href="/p/pricing">
                    <FormattedMessage id="SignupForm.seePricing" />
                  </a>
                </div>
              </div>
            </div>
            <div className={css.joinThemSection}>
              <div className={css.sectionContent}>
                <h4>Join the vibrant bazaar of opportunities!</h4>
                <H5></H5>
                <NamedLink name="SignupPage">
                  <Button>Join them</Button>
                </NamedLink>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

SignupFormComponent.defaultProps = { inProgress: false };

SignupFormComponent.propTypes = {
  inProgress: bool,
  termsAndConditions: node.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
